// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import componentUtils from "./componentUtils";
import huePubSub from 'utils/huePubSub';
import I18n from "../../utils/i18n";
export var SHOW_EVENT = 'show.create.directory.modal';
export var SHOWN_EVENT = 'create.directory.modal.shown';
var TEMPLATE = "\n  <!-- ko with: activeEntry -->\n  <form class=\"form-horizontal\">\n    <div class=\"modal-header\">\n      <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"".concat(I18n('Close'), "\"><span aria-hidden=\"true\">&times;</span></button>\n      <h2 class=\"modal-title\">").concat(I18n('Create Directory'), "</h2>\n    </div>\n    <div class=\"modal-body \">\n      <input id=\"newDirectoryName\" class=\"input large-as-modal\" type=\"text\" placeholder=\"").concat(I18n('Directory name'), "\" />\n    </div>\n    <div class=\"modal-footer\">\n      <input type=\"button\" class=\"btn\" data-dismiss=\"modal\" data-bind=\"click: function () { $('#newDirectoryName').val(null) }\" value=\"").concat(I18n('Cancel'), "\">\n      <input type=\"submit\" class=\"btn btn-primary disable-feedback\" value=\"").concat(I18n('Create'), "\" data-bind=\"click: function () { if ($('#newDirectoryName').val()) { $data.createDirectory($('#newDirectoryName').val()); $('#createDirectoryModal').modal('hide'); } }\"/>\n    </div>\n  </form>\n  <!-- /ko -->\n");
componentUtils.registerComponent('create-directory', undefined, TEMPLATE).then(function () {
  huePubSub.subscribe(SHOW_EVENT, function (docViewModel) {
    if (docViewModel.isTrash() || docViewModel.isTrashed() || !docViewModel.canModify()) {
      return;
    }
    var $createDirectoryModal = $('#createDirectoryModal');
    if ($createDirectoryModal.length > 0) {
      ko.cleanNode($createDirectoryModal[0]);
      $createDirectoryModal.remove();
    }
    var data = {
      params: docViewModel,
      descendantsComplete: function descendantsComplete() {
        huePubSub.publish(SHOWN_EVENT);
      }
    };
    $createDirectoryModal = $('<div id="createDirectoryModal" data-bind="descendantsComplete: descendantsComplete, component: { name: \'create-directory\', params: params }" data-keyboard="true" class="modal hide fade" tabindex="-1"></div>');
    $('body').append($createDirectoryModal);
    ko.applyBindings(data, $createDirectoryModal[0]);
    $createDirectoryModal.modal('show');
  });
});